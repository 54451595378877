import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import Typography, { TypographyProps } from 'common/Typography';
import Loader from 'components/Loader';
import SearchInfo from 'components/SearchInfo';
import CardListing from 'containers/CardListing';

import { useSearch } from 'hooks/useSearch';

import './SearchPage.scss';

const SearchPage: FC<SearchPageTypes.SearchPageProps> = ({
  data: {
    searchPage: {
      urls,
      seo,
      pageName,
      resultsText,
      noResultsText,
      pendingSearchText,
      productsTitle,
      articlesTitle,
    },
    allProductDetailPage: { productsList },
    allArticleDetailPage: { articlesList },
  },
}) => {
  const { productsHits, articlesHits, isLoading } = useSearch();
  const [isResults, setIsResults] = useState<boolean>();

  useEffect(() => {
    setIsResults(Boolean(productsHits.length) || Boolean(articlesHits.length));
  }, [productsHits, articlesHits]);

  const productsHitsUrls = productsHits?.map(({ url }) => url);
  const filteredProducts = productsList?.filter(({ url }) => productsHitsUrls.includes(url));

  const articlesHitsUrls = articlesHits?.map(({ url }) => url);
  const filteredArticles = articlesList?.filter(({ url }) => articlesHitsUrls.includes(url));

  const headingTypography: TypographyProps = {
    weight: 'bold',
    uppercase: true,
    padding: {
      top: { base: 'xxl', md: 'xxxl' },
      bottom: { base: 'xxl', md: 'xxxl' },
    },
  };

  return (
    <Layout {...{ urls, seo, background: 'sauvignon' }}>
      <div className="search-page">
        <Typography weight="bold" size={32} uppercase as="h1" align="center">
          {pageName}
        </Typography>
        <SearchInfo
          isLoading={isLoading}
          isResultsFound={isResults}
          resultsTitle={resultsText}
          pendingTitle={pendingSearchText}
          noResultsTitle={noResultsText}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <section className="search-page__results">
            {filteredProducts.length ? (
              <>
                <Typography
                  {...headingTypography}
                  as="h2"
                  size={{ base: 25, md: 35 }}
                  className="search-page__title"
                >
                  {productsTitle}
                </Typography>
                <CardListing cards={filteredProducts} variant="product" />
              </>
            ) : null}

            {filteredArticles.length ? (
              <>
                <Typography {...headingTypography} as="h2" size={{ base: 25, md: 35 }}>
                  {articlesTitle}
                </Typography>
                <div className="search-page__articles">
                  <CardListing cards={filteredArticles} variant="article" />
                </div>
              </>
            ) : null}
          </section>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SearchPageQuery($lang: String) {
    searchPage(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      pageName
      resultsText
      pendingSearchText
      noResultsText
      productsTitle
      articlesTitle
    }
    allProductDetailPage {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
    allArticleDetailPage {
      articlesList: nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default SearchPage;
