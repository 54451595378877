import { useEffect, useState } from 'react';
import { searchArticleIndex, searchProductIndex } from 'algolia/config';

import { getQueryString, isBrowser } from 'utils/browser';

import { getSearchHits } from './utils';

import { ArticlesSearchType, ProductsSearchType, UseSearchType } from './models.d';

export const useSearch: UseSearchType = () => {
  const [productsHits, setProductsHits] = useState<ProductsSearchType[]>([]);
  const [articlesHits, setArticlesHits] = useState<ArticlesSearchType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const searchParam = isBrowser() && getQueryString();

  useEffect(() => {
    setIsLoading(Boolean(searchParam));
    getSearchHits(searchParam, setProductsHits, searchProductIndex, setIsLoading);
    getSearchHits(searchParam, setArticlesHits, searchArticleIndex, setIsLoading);
  }, [searchParam]);

  return { productsHits, articlesHits, isLoading };
};
