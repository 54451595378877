import React from 'react';

import './Loader.scss';

const Loader = () => (
  <div data-testid="search-loader" className="loader">
    <div className="loader__wrapper">
      {[...Array(4)].map((_, i) => {
        const index = `loader__dot-wrapper:${i}`;

        return <span className="loader__dot-wrapper" key={index} />;
      })}
    </div>
  </div>
);

export default Loader;
