import { SearchIndex } from 'algoliasearch/lite';

export const getSearchHits = (
  searchQuery: string | null | false,
  stateSetter,
  searchIndex: SearchIndex,
  loadingSetter: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (searchQuery) {
    searchIndex.search(searchQuery).then((searchHits) => {
      const { hits } = searchHits;
      setTimeout(() => {
        loadingSetter(false);
      }, 500);
      stateSetter(hits);
    });
  }
};
