import React, { FC, useEffect, useMemo, useState } from 'react';

import { Container } from 'layout';
import Typography from 'common/Typography';
import SearchBar from 'components/SearchBar';

import { getQueryString, isBrowser } from 'utils/browser';

import { SearchInfoProps } from './models';

import './SearchInfo.scss';

const SearchInfo: FC<SearchInfoProps> = ({
  resultsTitle,
  noResultsTitle,
  pendingTitle,
  isResultsFound,
  isLoading,
}) => {
  const [title, setTitle] = useState<string>(pendingTitle);

  const [searchInfo, setSearchInfo] = useState<string>('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuery = useMemo(() => isBrowser() && getQueryString('q'), [searchInfo, title]);

  const searchtext = isResultsFound ? resultsTitle : noResultsTitle;
  useEffect(() => {
    if (!isLoading) {
      setSearchInfo(isResultsFound ? resultsTitle : noResultsTitle);
    }
    setTitle(isLoading ? pendingTitle : searchtext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isResultsFound, searchInfo]);

  return (
    <Container data-testid="search-info" className="search-info">
      <Typography size={26} className="color--rose-bud" padding={{ bottom: 'xs' }} align="center">
        {title}
        <span className="search-info__query color--rose-bud">{searchQuery}</span>
      </Typography>
      <SearchBar />
    </Container>
  );
};

export default SearchInfo;
