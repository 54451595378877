import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
);

export const productsIndex = `${process.env.GATSBY_ALGOLIA_PREFIX}-products`;
export const articlesIndex = `${process.env.GATSBY_ALGOLIA_PREFIX}-articles`;
export const filtersIndex = `${process.env.GATSBY_ALGOLIA_PREFIX}-filters`;

export const searchProductIndex = searchClient.initIndex(productsIndex);
export const searchArticleIndex = searchClient.initIndex(articlesIndex);
